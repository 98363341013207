import { useEffect } from 'react';
import { throttle } from 'lodash';

export type ScrollListenerHandler = (event: {
  scrollTop: number;
  documentHeight: number;
  y: number;
  target: { documentElement: HTMLElement };
}) => any;

const useScrollListener = (
  onScroll: ScrollListenerHandler | undefined,
  throttleTimeout = 500,
) => {
  useEffect(() => {
    if (!onScroll) {
      return;
    }

    const onScrollThrottled = throttle(onScroll, throttleTimeout, {
      leading: true,
    });

    // @ts-expect-error
    window?.addEventListener('scroll', onScrollThrottled, false);

    return () => {
      // @ts-expect-error
      window?.removeEventListener('scroll', onScrollThrottled, false);
    };
  }, [onScroll, throttleTimeout]);
};

export default useScrollListener;
