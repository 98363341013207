import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { PermissionContext } from '../context/permission-context';
import createHocName from '../services/create-hoc-name';
import createPermissionHelpers from '../services/create-permission-helpers';

export type WithPermissions = {
  permissions: string[];
} & ReturnType<typeof createPermissionHelpers>;

export default function withPermissions<
  T extends WithPermissions = WithPermissions,
>(WrappedComponent: React.ComponentType<T>) {
  const WithPermissions = (props: Omit<T, keyof WithPermissions>) => (
    <PermissionContext.Consumer>
      {(permissions) => (
        // @ts-expect-error
        <WrappedComponent
          permissions={permissions}
          {...createPermissionHelpers(permissions)}
          {...props}
        />
      )}
    </PermissionContext.Consumer>
  );

  WithPermissions.displayName = createHocName(
    'WithPermissions',
    WrappedComponent,
  );

  hoistNonReactStatics(WithPermissions, WrappedComponent);

  return WithPermissions;
}
