import { get } from 'lodash';
import {
  DESKTOP_LAYOUT_TYPES_TO_MOBILE,
  IS_MOBILE_DISPLAY_SETTINGS_ENABLED_PATH,
  IS_MOBILE_LAYOUT_SETTINGS_ENABLED_PATH,
  LAYOUT_PG_GRID,
  RELATED_POSTS_DISPLAY_PARAMS,
  RELATED_POSTS_DISPLAY_PARAMS_MOBILE,
  SECTION_RELATED_POSTS,
  getLayoutTypePath,
  isLayoutPGOneColumn,
  isLayoutPGSideBySide,
  type DesktopLayoutTypeSuportedByMobile,
  RELATED_POSTS_SETTINGS_PARAMS,
} from '@wix/communities-blog-client-common';
import {
  getAppSettings,
  getAppSettingsValue,
} from '../../common/selectors/app-settings-base-selectors';
import { getDefaultLayout } from '../../common/selectors/layout-selectors';
import { getIsMobileSettingEnabled } from '../../common/selectors/mobile-settings-selectors';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import { AppState } from '../../common/types';

export const getUseMobileDisplaySettings = (state: AppState) =>
  getIsMobile(state) &&
  getIsMobileSettingEnabled(state, IS_MOBILE_DISPLAY_SETTINGS_ENABLED_PATH);

export const getRelatedPostCount = (state: AppState) => {
  return getAppSettingsValue({
    state,
    key: RELATED_POSTS_SETTINGS_PARAMS.postCount.appSettingsPath,
    fallback: RELATED_POSTS_SETTINGS_PARAMS.postCount.defaultValue,
  });
};

export const getIsSeeAllLinkEnabled = (state: AppState) => {
  const param = getUseMobileDisplaySettings(state)
    ? RELATED_POSTS_DISPLAY_PARAMS_MOBILE
    : RELATED_POSTS_DISPLAY_PARAMS;

  return getAppSettingsValue({
    state,
    key: param.seeAllLinkEnabled.appSettingsPath,
    fallback: param.seeAllLinkEnabled.defaultValue,
  });
};

export const getIsWidgetTitleEnabled = (state: AppState) => {
  const param = getUseMobileDisplaySettings(state)
    ? RELATED_POSTS_DISPLAY_PARAMS_MOBILE
    : RELATED_POSTS_DISPLAY_PARAMS;

  return getAppSettingsValue({
    state,
    key: param.widgetTitleEnabled.appSettingsPath,
    fallback: param.widgetTitleEnabled.defaultValue,
  });
};

export const getRelatedPostsLayoutType = ({
  state,
  isMobile,
}: {
  state: AppState;
  isMobile?: boolean;
}) => {
  const defaultLayout = getDefaultLayout(state, false);
  const desktopLayoutType = getLayoutType({ state, defaultLayout });
  if (!isMobile) {
    return desktopLayoutType;
  }

  const isMobileLayoutSettingsEnabled = getIsMobileSettingEnabled(
    state,
    IS_MOBILE_LAYOUT_SETTINGS_ENABLED_PATH,
  );

  if (!isMobileLayoutSettingsEnabled) {
    return DESKTOP_LAYOUT_TYPES_TO_MOBILE[
      desktopLayoutType as DesktopLayoutTypeSuportedByMobile
    ];
  }

  const mobileLayoutType = getLayoutType({ state, isMobile: true });
  if (
    isLayoutPGSideBySide(mobileLayoutType) ||
    isLayoutPGOneColumn(mobileLayoutType)
  ) {
    return LAYOUT_PG_GRID;
  }

  return mobileLayoutType;
};

function getLayoutType({
  state,
  isMobile,
  defaultLayout,
}: {
  state: AppState;
  isMobile?: boolean;
  defaultLayout?: number;
}) {
  return get(
    getAppSettings(state),
    getLayoutTypePath(SECTION_RELATED_POSTS, isMobile),
    defaultLayout,
  );
}
