import { get, flowRight } from 'lodash';
import {
  getCardBorderWidthPath,
  isLayoutTextOnImage,
  LayoutType,
  Section,
} from '@wix/communities-blog-client-common';
import { connect } from '../components/runtime-context';

import { getAppSettings } from '../selectors/app-settings-base-selectors';
import withHocName from './with-hoc-name';
import withLayoutProps, {
  WithLayoutOwnProps,
  WithLayoutProps,
} from './with-layout-props';

const DEFAULT_WIDTH = 1;

export type WithCardBorderWidthOwnProps = WithLayoutOwnProps;

export type WithCardBorderWidth = WithLayoutProps &
  ReturnType<typeof connector>;

const connector = connect(
  (state, { layoutType, section }: WithLayoutOwnProps) => {
    return {
      borderWidth: isLayoutTextOnImage(layoutType as LayoutType)
        ? 0
        : (get(
            getAppSettings(state),
            getCardBorderWidthPath(
              layoutType as LayoutType,
              section as Section,
            ),
            DEFAULT_WIDTH,
          ) as number),
    };
  },
);

export default flowRight(
  withLayoutProps(),
  connector,
  withHocName('WithCardBorderWidth'),
);
