import { flowRight } from 'lodash';
import { ConnectedProps } from 'react-redux';
import { getIsPostPageBundle } from '@wix/communities-blog-client-common';
import { connect } from '../components/runtime-context';

import withHocName from './with-hoc-name';

export type WithFontClassName = ConnectedProps<typeof connector>;

const getFontClassName = (property: string) => `blog-post-${property}-font`;
const getFontClassNameWithStyle = (property: string) =>
  `blog-post-${property}-style-font`;

const connector = connect((state) => ({
  titleFontClassName: getFontClassName('title'),
  contentFontClassName: getFontClassName(
    getIsPostPageBundle(state) ? 'page' : 'description',
  ),
  contentFontClassNameWithStyle: getFontClassNameWithStyle('description'),
}));

export default flowRight(connector, withHocName('WithFontClassName'));
