import React, { useCallback, useEffect, useMemo } from 'react';
import { ConnectedProps } from 'react-redux';
import { isSSR } from '@wix/communities-blog-client-common';
import useScrollListener, {
  ScrollListenerHandler,
} from '../../hooks/use-scroll-listener';
import scrollParent from '../../services/scroll-parent';
import {
  isPreview as getIsPreview,
  isSite as getIsSite,
} from '../../store/basic-params/basic-params-selectors';
import { connect } from '../runtime-context';

type OwnProps = {
  hasMore?: boolean;
  pageStart?: number;
  threshold?: number;
  loader?: React.ReactNode;
  loadMore?: (page: number) => Promise<any>;
  isLoading?: boolean;
  entityCount?: number;
  pageSize?: number;
};

type Props = OwnProps & ConnectedProps<typeof connector>;

const LoadMore: React.FC<Props> = ({
  hasMore = false,
  pageStart = 2,
  threshold = 250,
  children,
  loader,
  isLoading: isLoadingProp,
  isPreview,
  isSite,
  loadMore,
  componentId,
  entityCount,
  pageSize,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const ref = React.useRef({ page: pageStart, pageStart });

  const pageLimit = useMemo(() => {
    if (!entityCount) {
      return;
    }
    if (!pageSize) {
      return entityCount;
    }
    return Math.ceil(entityCount / pageSize);
  }, [entityCount, pageSize]);

  const handleLoad = useCallback(() => {
    if (isLoadingProp || isLoading || !pageLimit) {
      return;
    }

    if (ref.current.page <= pageLimit) {
      setIsLoading(true);

      Promise.resolve(loadMore?.(ref.current.page)).then(() => {
        setIsLoading(false);
      });

      ref.current.page++;
    }
  }, [isLoading, isLoadingProp, loadMore]);

  const shouldLoadMoreInEditor: ScrollListenerHandler = useCallback(
    (scroll) => {
      const element = scrollParent();

      if (!hasMore || isLoadingProp || isLoading || isSSR() || !element) {
        return false;
      }

      const position = scroll.scrollTop + scroll.documentHeight + threshold;
      const end = element.scrollHeight + scroll.y;

      return position >= end;
    },
    [hasMore, isLoadingProp, isLoading, threshold],
  );

  const shouldLoadMore = useCallback(() => {
    if (!hasMore || isLoadingProp || isLoading || isSSR()) {
      return false;
    }

    const component = window.document.getElementById(componentId);

    if (component == null) {
      return false;
    }

    const { height, top } = component.getBoundingClientRect();

    return window.innerHeight + threshold > height + top;
  }, [componentId, hasMore, isLoading, isLoadingProp, threshold]);

  const handleScroll: ScrollListenerHandler = useCallback(
    (scroll) => {
      const shouldHandle =
        isSite || isPreview ? shouldLoadMore() : shouldLoadMoreInEditor(scroll);

      if (shouldHandle) {
        handleLoad();
      }
    },
    [handleLoad, isPreview, isSite, shouldLoadMore, shouldLoadMoreInEditor],
  );

  // Reset page when pageStart prop changes
  useEffect(() => {
    const pageStartChanged = pageStart !== ref.current.pageStart;

    if (pageStartChanged) {
      ref.current = { page: pageStart, pageStart };
    }
  }, [pageStart]);

  useScrollListener(handleScroll);

  return (
    <div>
      {children}
      {hasMore && (isLoadingProp || isLoading) && loader}
    </div>
  );
};

const connector = connect((state, ownProps, actions, host) => ({
  isSite: getIsSite(state),
  isPreview: getIsPreview(state),
  componentId: host.id,
}));

export default connector(LoadMore);
