import React, { Component } from 'react';
import classNames from 'classnames';
import { flowRight, isUndefined, isEqual, get } from 'lodash';
import { ProGallery, GALLERY_CONSTS } from 'pro-gallery'; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

import { LayoutFixer } from '@wix/pro-gallery-layout-fixer';
import {
  isLayoutTextOnImage,
  isLayoutSlider,
  SECTIONS,
  getVideoSettingsWithProGalleryValues,
  PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS,
  resolveId,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_USE_LAYOUT_FIXER } from '@wix/communities-blog-experiments';

import {
  isClickTargetItemMedia,
  isPGEventItemClicked,
} from '../../constants/pro-gallery-events';
import { TEXT_PLACEMENT } from '../../constants/pro-gallery-options';

import withExperiment from '../../hoc/with-experiment';

import withFeedBorderWidth from '../../hoc/with-feed-border-width';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import withIsFeedDesignEnabled from '../../hoc/with-is-feed-design-enabled';
import withTranslate from '../../hoc/with-translate';
import {
  getIsPostListFullWidthEnabled,
  getLayoutPostSize,
  getLayoutSpacing,
  getLayoutContentHeight,
  getFeedBorderColor,
  getLayoutImageRatio,
  getLayoutMargins,
  getLayoutImageAlignment,
  getLayoutImageProportions,
  getLayoutImageWidth,
  getLayoutImageResizingMode,
  getIsCreatedWithResponsiveEditor,
  getLayoutSidesPadding,
  getLayoutImageCropType,
  getUseMobileLayoutSettings,
  getLayoutCardsRowType,
  getLayoutPostsPerRow,
  getLayoutContentHeightType,
} from '../../selectors/app-settings-selectors';

import { isHoveringBehaviourNeverShow } from '../../selectors/pro-gallery-options-selectors';
import { getProGalleryViewMode } from '../../selectors/progallery-view-mode-selectors';
import {
  getContainer,
  getPGEmptyCoverImage,
  getScrollingElement,
  getWrapperStyles,
  getIsPrerenderMode,
} from '../../services/post-list-pro-gallery';
import {
  getIsMobile,
  getIsRTL,
  isSeo,
  isSSR,
} from '../../store/basic-params/basic-params-selectors';
import { getPostPageSectionUrl } from '../../store/topology/topology-selectors';
import LoadMore from '../load-more/load-more';
import Loader from '../loader';
import withResponsiveContext from '../responsive-listener/with-responsive-context';
import { connect } from '../runtime-context';
import calculateContentHeight from './calculate-content-height';
import { createInfoRenderer } from './create-info-renderer';
import { createOptions } from './gallery-options';
import { mapPostToPGItem } from './map-post-to-pro-gallery-item';
import { resizeMediaUrl } from './resizeMediaUrl';

import styles from './post-list-pro-gallery.scss';

class PostListProGallery extends Component {
  isPrerenderMode = true;

  componentDidMount() {
    if (this.props.createdWithResponsiveEditor) {
      document.documentElement.classList.add('enable-scroll');
    }
  }

  renderProGallery() {
    const {
      t,
      allPosts,
      borderColor,
      borderWidth,
      createdWithResponsiveEditor,
      hostWidth,
      layoutContentHeight,
      layoutImageAlignment,
      layoutImageProportions,
      layoutImageWidth,
      layoutImageResizingMode,
      layoutImageRatio,
      layoutMargins,
      layoutName,
      layoutPostSize,
      layoutCardsRowType,
      layoutPostsPerRow,
      layoutSidesPadding,
      layoutSpacing,
      layoutType,
      layoutImageCropType,
      onLikeClick,
      rootWidth,
      isRTL,
      section,
      hideAuthorBadge,
      canSeeMoreButton,
      className,
      hideCoverImage,
      layoutAutoHeightEnabled,
      isMobile,
      layoutOptions = {},
      videoSettings,
      useLayoutFixerExperimentEnabled,
      useMobilePostListLayout,
      getPostClassName,
      visibleActions,
      isSEO,
    } = this.props;

    const layoutTextOnImage = isLayoutTextOnImage(layoutType);
    const layoutImageRatioValue = [
      null,
      16 / 9,
      4 / 3,
      1,
      3 / 4,
      9 / 16,
      16 / 7,
    ][Number(layoutImageRatio)];
    const textBoxAlignment = [
      null,
      TEXT_PLACEMENT.SHOW_ON_THE_LEFT,
      TEXT_PLACEMENT.SHOW_ON_THE_RIGHT,
    ][Number(layoutImageAlignment)];

    const container = getContainer({
      borderWidth,
      createdWithResponsiveEditor,
      isMobile,
      layoutContentHeight,
      layoutImageRatioValue,
      layoutMargins,
      layoutPostSize,
      layoutType,
      hostWidth,
      rootWidth,
      section,
      useLayoutFixerExperimentEnabled,
      useMobilePostListLayout,
    });

    const options = createOptions(
      layoutType,
      {
        layoutPostSize: isLayoutSlider(layoutType) ? hostWidth : layoutPostSize,
        layoutCardsRowType,
        layoutPostsPerRow,
        layoutSpacing,
        layoutContentHeight,
        layoutImageCropType,
        layoutImageRatio: layoutImageRatioValue,
        textBoxAlignment,
        layoutImageProportions,
        layoutImageWidth,
        layoutImageResizingMode,
        isRTL,
        videoSettings,
        containerWidth: container.width,
        hideCoverImage,
        layoutAutoHeightEnabled,
        ...layoutOptions,
      },
      {
        width: borderWidth,
        color: borderColor,
      },
    );

    if (!isEqual(this.options, options)) {
      this.options = options;
    }

    if (
      allPosts !== this.prevAllPosts ||
      hideCoverImage !== this.prevHideCoverImage ||
      layoutType !== this.prevLayoutType ||
      layoutSidesPadding !== this.prevLayoutSidesPadding ||
      canSeeMoreButton !== this.prevCanSeeMoreButton ||
      textBoxAlignment !== this.prevTextBoxAlignment
    ) {
      const emptyCoverImage = getPGEmptyCoverImage(layoutType);
      this.items = allPosts.map((post) =>
        mapPostToPGItem(post, hideCoverImage, emptyCoverImage),
      );

      this.postInfoRenderer = createInfoRenderer({
        prevAllPosts: this.prevAllPosts || [],
        allPosts,
        layoutType,
        layoutName,
        section,
        layoutSidesPadding,
        canSeeMoreButton,
        hideAuthorBadge,
        textBoxAlignment,
        options: this.options,
        onLikeClick,
        getPostClassName,
        visibleActions,
      });
      if (allPosts !== this.prevAllPosts) {
        this.prevAllPosts = allPosts;
      }
      this.prevHideCoverImage = hideCoverImage;
      this.prevLayoutType = layoutType;
      this.prevLayoutSidesPadding = layoutSidesPadding;
      this.prevCanSeeMoreButton = canSeeMoreButton;
      this.prevTextBoxAlignment = textBoxAlignment;
    }

    const scrollingElement = getScrollingElement();

    const eventsListener = (eventName, eventData) => {
      if (
        !layoutTextOnImage &&
        isPGEventItemClicked(eventName) &&
        isClickTargetItemMedia(eventData)
      ) {
        const currentPost = allPosts.find(
          (post) => resolveId(post) === eventData.id,
        );

        if (
          !currentPost.slug ||
          (currentPost.coverImage?.videoMetadata &&
            videoSettings.play === 'onClick')
        ) {
          return;
        }

        this.props.navigateProGalleryWithinPostPage(currentPost.slug);
      }
    };

    const wrapperStyles = getWrapperStyles({
      container,
      createdWithResponsiveEditor,
      isMobile,
      layoutMargins,
      layoutPostSize,
      layoutType,
      section,
    });

    const domId = this.props.domId || 'pro-blog';

    const customComponents = {
      customHoverRenderer: isHoveringBehaviourNeverShow(options)
        ? null
        : this.postInfoRenderer,
      customInfoRenderer: this.postInfoRenderer,
    };

    this.isPrerenderMode = getIsPrerenderMode({
      hostWidth,
      isPrerenderMode: this.isPrerenderMode,
      isSEO,
      rootWidth,
    });

    const containsEmptyItems = this.items.some((item) => item.url === ''); // Layout fixer doesn't support cards without images
    const showLayoutFixer =
      !containsEmptyItems &&
      useLayoutFixerExperimentEnabled &&
      this.isPrerenderMode;

    return (
      <div
        key="pg-container"
        data-hook="post-list-pro-gallery-container"
        style={wrapperStyles}
        className={classNames(
          `post-list-pro-gallery-${layoutName}`,
          isMobile && this.items.length === 1
            ? 'post-list-pro-gallery-flat'
            : '',
          textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_RIGHT
            ? 'gallery-left-align'
            : 'gallery-right-align',
          styles.overrides,
          className,
        )}
      >
        <ProGallery
          key={`pg-posts-${layoutPostSize}`}
          id={domId}
          items={this.items}
          totalItemsCount={this.items.length}
          options={this.options}
          container={container}
          scrollingElement={scrollingElement}
          eventsListener={eventsListener}
          viewMode={this.props.viewMode}
          deviceType={this.props.deviceType}
          customComponents={customComponents}
          createMediaUrl={resizeMediaUrl}
          proGalleryRegionLabel={t('pro-gallery.parent-container-aria-label')}
          isPrerenderMode={this.isPrerenderMode}
        />
        {showLayoutFixer ? (
          <LayoutFixer
            id={domId}
            items={this.items}
            isPrerenderMode={this.isPrerenderMode}
            options={this.options}
          />
        ) : null}
      </div>
    );
  }

  render() {
    if (!this.props.loadMore) {
      return this.renderProGallery();
    }

    const { isLoading, allPosts, entityCount, loadMore, pageStart, pageSize } =
      this.props;

    return (
      <LoadMore
        loadMore={loadMore}
        loader={<Loader />}
        isLoading={isLoading}
        hasMore={allPosts.length < entityCount}
        pageStart={pageStart}
        entityCount={entityCount}
        pageSize={pageSize}
      >
        {this.renderProGallery()}
      </LoadMore>
    );
  }
}

PostListProGallery.propTypes = {
  t: PropTypes.func,
  onLikeClick: PropTypes.func.isRequired,
  loadMore: PropTypes.func,
  currentPagePosts: PropTypes.array,
  category: PropTypes.object,
  allPosts: PropTypes.array,
  location: PropTypes.object,
  isMobile: PropTypes.bool,
  layoutType: PropTypes.number.isRequired,
  layoutName: PropTypes.string.isRequired,
  entityCount: PropTypes.number,
  pageStart: PropTypes.number,
  isLoading: PropTypes.bool,
  showCreatePostAction: PropTypes.bool.isRequired,
  isMetadataFooterVisible: PropTypes.bool,
  isPostListFullWidthEnabled: PropTypes.bool.isRequired,
  postPageSectionUrl: PropTypes.string,
  hostWidth: PropTypes.number,
  navigateProGalleryWithinPostPage: PropTypes.func,
  layoutPostSize: PropTypes.number,
  layoutCardsRowType: PropTypes.number,
  layoutPostsPerRow: PropTypes.number,
  layoutSpacing: PropTypes.number,
  layoutContentHeight: PropTypes.number,
  layoutAutoHeightEnabled: PropTypes.bool,
  layoutImageRatio: PropTypes.number,
  layoutMargins: PropTypes.number,
  layoutImageAlignment: PropTypes.number,
  layoutImageProportions: PropTypes.number,
  layoutImageWidth: PropTypes.number,
  layoutImageResizingMode: PropTypes.number,
  viewMode: PropTypes.string,
  deviceType: PropTypes.oneOf([
    GALLERY_CONSTS.deviceType.MOBILE,
    GALLERY_CONSTS.deviceType.DESKTOP,
  ]),
  borderWidth: PropTypes.number.isRequired,
  layoutSidesPadding: PropTypes.number.isRequired,
  layoutImageCropType: PropTypes.number.isRequired,
  borderColor: PropTypes.object.isRequired,
  createdWithResponsiveEditor: PropTypes.bool,
  rootWidth: PropTypes.number,
  isRTL: PropTypes.bool.isRequired,
  hideAuthorBadge: PropTypes.bool,
  canSeeMoreButton: PropTypes.func.isRequired,
  layoutOptions: PropTypes.object,
  hideCoverImage: PropTypes.bool,
  isInIFrame: PropTypes.bool.isRequired,
  section: PropTypes.oneOf(SECTIONS),
  videoSettings: PropTypes.exact({
    play: PropTypes.string.isRequired,
    playbackSpeed: PropTypes.string.isRequired,
    sound: PropTypes.bool.isRequired,
    loop: PropTypes.bool.isRequired,
    showPlayButton: PropTypes.bool.isRequired,
  }),
  useLayoutFixerExperimentEnabled: PropTypes.bool,
  getPostClassName: PropTypes.func.isRequired,
  visibleActions: PropTypes.arrayOf(PropTypes.string),
  isSSR: PropTypes.bool,
  isSEO: PropTypes.bool,
  pageLimit: PropTypes.number,
};

const getLayoutStyleParamProps = ({
  state,
  section,
  hostWidth,
  rootWidth,
  layoutMargins,
  layoutType,
}) => {
  const contentHeightType = getLayoutContentHeightType({
    state,
    section,
    layoutType,
  });
  const layoutAutoHeightEnabled =
    contentHeightType === PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS.AUTO;
  const selectorProps = { state, section, layoutType };
  return {
    layoutAutoHeightEnabled,
    layoutContentHeight: layoutAutoHeightEnabled
      ? calculateContentHeight(state, section, layoutType)
      : getLayoutContentHeight(selectorProps),
    layoutImageAlignment: getLayoutImageAlignment(selectorProps),
    layoutImageProportions: getLayoutImageProportions(selectorProps),
    layoutImageWidth: getLayoutImageWidth(selectorProps),
    layoutImageResizingMode: getLayoutImageResizingMode(selectorProps),
    layoutImageCropType: getLayoutImageCropType(selectorProps),
    layoutImageRatio: getLayoutImageRatio(selectorProps),
    layoutCardsRowType: getLayoutCardsRowType(selectorProps),
    layoutPostsPerRow: getLayoutPostsPerRow(selectorProps),
    layoutPostSize: getLayoutPostSize({
      state,
      hostWidth,
      rootWidth,
      layoutMargins,
      section,
      layoutType,
    }),
    layoutSidesPadding: getLayoutSidesPadding(selectorProps),
    layoutSpacing: getLayoutSpacing(selectorProps),
  };
};

const mapRuntimeToProps = (
  state,
  { section, layoutType, layoutName, rootWidth, layoutDefaults = {} },
  actions,
  host,
) => {
  const hostWidth = get(host, 'dimensions.width');
  const createdWithResponsiveEditor = getIsCreatedWithResponsiveEditor(state);
  const layoutMargins = createdWithResponsiveEditor
    ? null
    : getLayoutMargins({ state, section, layoutType, layoutName });
  const {
    layoutContentHeight,
    layoutImageAlignment,
    layoutImageProportions,
    layoutImageWidth,
    layoutImageResizingMode,
    layoutImageRatio,
    layoutPostSize,
    layoutSidesPadding,
    layoutImageCropType,
    layoutSpacing,
    layoutCardsRowType,
    layoutPostsPerRow,
    layoutAutoHeightEnabled,
  } = getLayoutStyleParamProps({
    state,
    rootWidth,
    hostWidth,
    layoutMargins,
    section,
    layoutType,
  });

  return {
    scroll: state.scroll,
    isPostListFullWidthEnabled: getIsPostListFullWidthEnabled(state),
    onLikeClick: actions.incrementPostLikeCount,
    postPageSectionUrl: getPostPageSectionUrl(state),
    hostWidth,
    navigateProGalleryWithinPostPage: actions.navigateProGalleryWithinPostPage,
    borderColor: getFeedBorderColor({ state, section }),
    createdWithResponsiveEditor,
    isRTL: getIsRTL(state),
    deviceType: getIsMobile(state)
      ? GALLERY_CONSTS.deviceType.MOBILE
      : GALLERY_CONSTS.deviceType.DESKTOP,
    layoutContentHeight: isUndefined(layoutContentHeight)
      ? layoutDefaults.contentHeight
      : layoutContentHeight,
    layoutImageAlignment: isUndefined(layoutImageAlignment)
      ? layoutDefaults.imageAlignment
      : layoutImageAlignment,
    layoutImageProportions: isUndefined(layoutImageProportions)
      ? layoutDefaults.imageProportions
      : layoutImageProportions,
    layoutImageWidth: isUndefined(layoutImageWidth)
      ? layoutDefaults.layoutImageWidth
      : layoutImageWidth,
    layoutImageResizingMode: isUndefined(layoutImageResizingMode)
      ? layoutDefaults.layoutImageResizingMode
      : layoutImageResizingMode,
    layoutImageRatio: isUndefined(layoutImageRatio)
      ? layoutDefaults.imageRatio
      : layoutImageRatio,
    layoutImageCropType: isUndefined(layoutImageCropType)
      ? layoutDefaults.imageCropType
      : layoutImageCropType,
    layoutMargins: isUndefined(layoutMargins)
      ? layoutDefaults.margins
      : layoutMargins,
    layoutPostSize: isUndefined(layoutPostSize)
      ? layoutDefaults.layoutPostSize
      : layoutPostSize,
    layoutCardsRowType: isUndefined(layoutCardsRowType)
      ? layoutDefaults.layoutCardsRowType
      : layoutCardsRowType,
    layoutPostsPerRow: isUndefined(layoutPostsPerRow)
      ? layoutDefaults.layoutPostsPerRow
      : layoutPostsPerRow,
    layoutAutoHeightEnabled: isUndefined(layoutAutoHeightEnabled)
      ? layoutDefaults.layoutAutoHeightEnabled
      : layoutAutoHeightEnabled,
    layoutSidesPadding: isUndefined(layoutSidesPadding)
      ? layoutDefaults.sidesPadding
      : layoutSidesPadding,
    layoutSpacing: isUndefined(layoutSpacing)
      ? layoutDefaults.spacing
      : layoutSpacing,
    registerToScroll: host.registerToScroll,
    viewMode: getProGalleryViewMode(state, rootWidth),
    videoSettings: getVideoSettingsWithProGalleryValues(state, section),
    useMobilePostListLayout: getUseMobileLayoutSettings(state),
    isSSR: isSSR(state),
    isSEO: isSeo(state),
    rootWidth,
    isMobile: getIsMobile(state),
  };
};

export default flowRight(
  withFeedMetadataSettings,
  withTranslate,
  withResponsiveContext,
  connect(mapRuntimeToProps),
  withFeedBorderWidth,
  withIsFeedDesignEnabled,
  withExperiment({
    useLayoutFixerExperimentEnabled: EXPERIMENT_USE_LAYOUT_FIXER,
  }),
)(PostListProGallery);
