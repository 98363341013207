import { flowRight } from 'lodash';
import { ConnectedProps } from 'react-redux';
import {
  AUTHOR_INFO_TYPE_NAME,
  getMainCategory,
  hasTPACommentsSettings,
  getTPASettingsIsCommentsEnabled,
  getTPASettingsIsPostPageSplitEnabled,
  isExperimentEnabled,
  Section,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_ENABLE_RATINGS_IN_VIEWER } from '@wix/communities-blog-experiments';
import { connect } from '../components/runtime-context';
import {
  getIsViewCountEnabled,
  getIsCommentCountEnabled,
  getIsLikeCountEnabled,
  getIsAuthorNameEnabled,
  getIsPostUpdatedDateEnabled,
  getIsCommentsEnabled,
  getIsRecentPostsEnabled,
  getIsCategoryLabelsEnabled,
  getIsPostTitleEnabled,
  getIsPostPublishDateEnabled,
  getIsReadingTimeEnabled,
  getIsPostDescriptionEnabled,
  getAuthorInfoType,
  getIsAuthorPictureEnabled,
  getIsAuthorBadgeEnabled,
  getIsMoreOptionsMenuEnabled,
  getIsRelatedPostsEnabled,
  getRelatedPostsLabel,
  getIsCategoryLabelEnabled,
  getIsPostRatingEnabled,
} from '../selectors/app-settings-selectors';
import { getIsPostInPreview } from '../store/is-post-in-preview/is-post-in-preview-selectors';
import { NormalizedPost } from '../types';
import withHocName from './with-hoc-name';

export type WithFeedMetadataSettings = ConnectedProps<typeof connector>;

export type WithFeedMetadataSettingsOwnProps = {
  section: Section;
  hideAuthorBadge: boolean;
  post: NormalizedPost;
};

const connector = connect(
  (state, ownProps: WithFeedMetadataSettingsOwnProps) => {
    const isPostInPreview = getIsPostInPreview(state);
    const isPostPageSplitEnabled = getTPASettingsIsPostPageSplitEnabled(state);
    const isCommentsEnabled = isPostInPreview
      ? false
      : hasTPACommentsSettings(state)
      ? getTPASettingsIsCommentsEnabled(state)
      : getIsCommentsEnabled(state);
    const showComments = !isPostPageSplitEnabled && isCommentsEnabled;
    const isRecentPostsEnabled =
      !isPostInPreview && getIsRecentPostsEnabled(state);
    const isRelatedPostsEnabled =
      !isPostInPreview && getIsRelatedPostsEnabled(state);
    const showRecentPosts = !isPostPageSplitEnabled && isRecentPostsEnabled;
    const showRelatedPosts = !isPostPageSplitEnabled && isRelatedPostsEnabled;
    const relatedPostsLabelKey =
      !isPostInPreview && getRelatedPostsLabel(state);
    const showViewCount = !isPostInPreview && getIsViewCountEnabled(state);
    const showCommentCount =
      isCommentsEnabled && getIsCommentCountEnabled(state);
    const showLikeCount = !isPostInPreview && getIsLikeCountEnabled(state);
    const showAuthorName = getIsAuthorNameEnabled(state);
    const authorInfoType = getAuthorInfoType(state);
    const showPostUpdatedDate = getIsPostUpdatedDateEnabled(state);
    const showCategoryLabels = getIsCategoryLabelsEnabled(state);
    const showPostTitle = getIsPostTitleEnabled(state);
    const showPublishDate = getIsPostPublishDateEnabled(state);
    const showReadingTime = getIsReadingTimeEnabled(state);
    const showPostDescription = getIsPostDescriptionEnabled(
      state,
      ownProps.section,
    );
    const oldShowAuthorPictureValue = authorInfoType !== AUTHOR_INFO_TYPE_NAME;
    const showAuthorPicture = getIsAuthorPictureEnabled(
      state,
      oldShowAuthorPictureValue,
    );
    const showAuthorBadge =
      !ownProps.hideAuthorBadge && getIsAuthorBadgeEnabled(state);
    const showMoreOptionsMenu = getIsMoreOptionsMenuEnabled(state);
    const showCategoryLabel =
      getMainCategory(state, ownProps.post) && getIsCategoryLabelEnabled(state);
    const showPostRating =
      isExperimentEnabled(state, EXPERIMENT_ENABLE_RATINGS_IN_VIEWER) &&
      getIsPostRatingEnabled(state);

    return {
      showComments,
      showRecentPosts,
      showViewCount,
      showCommentCount,
      showLikeCount,
      showAuthorName,
      showAuthorPicture,
      showAuthorBadge,
      authorInfoType,
      showPostUpdatedDate,
      showCategoryLabels,
      showPostTitle,
      showPublishDate,
      showReadingTime,
      showPostDescription,
      showMoreOptionsMenu,
      showCategoryLabel,
      isMetadataFooterVisible:
        !isPostInPreview &&
        (showViewCount || showCommentCount || showLikeCount),
      isMetadataHeaderVisible:
        showAuthorName || showPublishDate || showReadingTime,
      showRelatedPosts,
      relatedPostsLabelKey,
      showPostRating,
    };
  },
);
export default flowRight(connector, withHocName('WithFeedMetadataSettings'));
