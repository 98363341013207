import { get } from 'lodash';
import { AppState } from '../types';

export const getAppSettings = (state: AppState) => state.appSettings;

export const getAppSettingsValue = ({
  state,
  key,
  fallback = undefined,
}: {
  state: AppState;
  key: string;
  fallback?: any;
}) => get(getAppSettings(state), key, fallback);

export const getAppSettingsNumber = (
  state: AppState,
  key: string,
  fallback: number = 1,
) => getAppSettingsValue({ state, key: `style.numbers.${key}`, fallback });

export const getAppSettingsFont = (
  state: AppState,
  key: string,
  fallback: any = undefined,
) => getAppSettingsValue({ state, key: `style.fonts.${key}`, fallback });
