import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { flowRight, noop } from 'lodash';
import PropTypes from 'prop-types';

import {
  SECTIONS,
  SECTION_HOMEPAGE,
  SECTION_MY_POSTS,
  SECTION_POST_LIST,
  SECTION_RELATED_POSTS,
} from '@wix/communities-blog-client-common';

import { connect } from '../components/runtime-context';
import { getRoute } from '../router/router-selectors';
import {
  getIsCreatedWithResponsiveEditor,
  getIsFeedDesignPropsMigrated,
  getUseMobileDesignSettings,
} from '../selectors/app-settings-selectors';
import { shouldApplyPostDesignInFeed } from '../selectors/full-post-selectors';
import { getSection } from '../selectors/section-selectors';
import { isInRouteUsingFeedPage } from '../services/detect-route';
import { createFeedClassNameGenerator } from '../services/layout-config';
import withHocName from './with-hoc-name';

const withIsFeedDesignEnabled = (WrappedComponent) => {
  const WithIsFeedDesignEnabled = ({
    isCreatedWithResponsiveEditor,
    isFeedDesignPropsMigrated,
    isInFeed,
    isInSearch,
    section,
    useMobileSettings,
    shouldApplyPostDesignInFeed,
    ...props
  }) => {
    const shouldApplyDesignInFeed = isInFeed && !shouldApplyPostDesignInFeed;
    const applyFeedDesign =
      section === SECTION_POST_LIST ||
      section === SECTION_RELATED_POSTS ||
      section === SECTION_MY_POSTS ||
      ((isFeedDesignPropsMigrated || isCreatedWithResponsiveEditor) &&
        (shouldApplyDesignInFeed || isInSearch));
    const getPostClassName = applyFeedDesign
      ? createFeedClassNameGenerator(section, useMobileSettings)
      : noop;

    return (
      <WrappedComponent
        getPostClassName={getPostClassName}
        applyFeedDesign={applyFeedDesign}
        section={section}
        {...props}
      />
    );
  };

  WithIsFeedDesignEnabled.propTypes = {
    isFeedDesignPropsMigrated: PropTypes.bool.isRequired,
    section: PropTypes.oneOf(SECTIONS),
    isInFeed: PropTypes.bool.isRequired,
    isInSearch: PropTypes.bool.isRequired,
    isCreatedWithResponsiveEditor: PropTypes.bool.isRequired,
    useMobileSettings: PropTypes.bool.isRequired,
  };

  WithIsFeedDesignEnabled.defaultProps = { section: SECTION_HOMEPAGE };

  hoistNonReactStatics(WithIsFeedDesignEnabled, WrappedComponent);

  const mapRuntimeToProps = (state, { section }) => {
    const route = getRoute(state);
    return {
      section: getSection(state) || section,
      isInFeed: isInRouteUsingFeedPage(route),
      isInSearch: false, // TODO: isInSearchPage(route),
      isCreatedWithResponsiveEditor: getIsCreatedWithResponsiveEditor(state),
      isFeedDesignPropsMigrated: getIsFeedDesignPropsMigrated(state),
      useMobileSettings: getUseMobileDesignSettings(state),
      shouldApplyPostDesignInFeed: shouldApplyPostDesignInFeed(state),
    };
  };

  return flowRight(
    connect(mapRuntimeToProps),
    withHocName('WithIsFeedDesignEnabled'),
  )(WithIsFeedDesignEnabled);
};

export default withIsFeedDesignEnabled;
