import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { resolveId } from '@wix/communities-blog-client-common';

import AuthorWithPostDetails from '../../../post-page/components/author-with-post-details';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import alignmentStyles from '../../styles/post-list-item-alignment.scss';
import Avatar from '../avatar';
import ProfileLink from '../link/profile-link';
import { MoreButton } from '../more-button';
import { importPostActions } from '../post-actions';
import PostHeaderIcons from '../post-header-icons';
import styles from './post-list-item-header.scss';

function PostListItemHeader({
  className,
  isMetadataHeaderVisible,
  moreButtonClass,
  moreButtonIcon,
  post,
  showAuthorName,
  showAuthorPicture,
  showAuthorBadge,
  showHeaderIcons,
  showMoreButton,
  showProfileImage,
  showPublishDate,
  showReadingTime,
  style,
  type,
  showCategoryLabel,
  visibleActions,
}) {
  const withoutHeaderMetadata = !isMetadataHeaderVisible;
  const containerClassName = classNames(
    styles.container,
    styles[type],
    'blog-post-metadata-font',
    'post-header',
    withoutHeaderMetadata && styles.withoutHeaderMetadata,
    className,
    showCategoryLabel && styles.withCategoryLabel,
    showAuthorName &&
      (showPublishDate || showReadingTime || showAuthorPicture) &&
      styles.spacingBottom,
  );
  const moreButtonId = `more-button-${resolveId(post)}`;
  const buttonProps = {
    id: moreButtonId,
    ...(moreButtonIcon ? { icon: moreButtonIcon } : {}),
  };

  return (
    <div className={containerClassName} style={style}>
      <div
        className={classNames([
          styles.metadata,
          showMoreButton && styles.withThreeDots,
          alignmentStyles.metadataContainer,
        ])}
      >
        {isMetadataHeaderVisible && (
          <>
            {showAuthorName && showProfileImage && showAuthorPicture && (
              <div className={alignmentStyles.avatar}>
                <ProfileLink user={post.owner}>
                  <Avatar user={post.owner} isNameVisible={false} />
                </ProfileLink>
              </div>
            )}
            <AuthorWithPostDetails
              post={post}
              type={type}
              className={alignmentStyles.postDetails}
              showAuthorName={showAuthorName}
              showAuthorBadge={showAuthorBadge}
              showPublishDate={showPublishDate}
              showReadingTime={showReadingTime}
            />
          </>
        )}
      </div>
      {(showHeaderIcons || showMoreButton) && (
        <div
          className={classNames(styles.postOptions, alignmentStyles.options)}
        >
          {showHeaderIcons ? (
            <PostHeaderIcons
              post={post}
              className={styles.icons}
              iconClassName="blog-button-fill"
            />
          ) : (
            <div className={styles.icons} />
          )}
          <div
            className={classNames(
              styles.moreButton,
              styles[type],
              'blog-more-icon-fill',
              moreButtonClass,
            )}
          >
            {showMoreButton && (
              <MoreButton {...buttonProps}>
                {async () => {
                  const PostActions = await importPostActions();
                  return (
                    <PostActions
                      post={post}
                      focusOnCloseId={moreButtonId}
                      visibleActions={visibleActions}
                    />
                  );
                }}
              </MoreButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

PostListItemHeader.propTypes = {
  authorInfoType: PropTypes.number,
  className: PropTypes.string,
  isMetadataHeaderVisible: PropTypes.bool,
  moreButtonClass: PropTypes.string,
  moreButtonIcon: PropTypes.node,
  post: PropTypes.object,
  showAuthorName: PropTypes.bool,
  showAuthorPicture: PropTypes.bool,
  showAuthorBadge: PropTypes.bool,
  showHeaderIcons: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showProfileImage: PropTypes.bool,
  showPublishDate: PropTypes.bool,
  showReadingTime: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.string.isRequired,
  showCategoryLabel: PropTypes.bool,
  visibleActions: PropTypes.arrayOf(PropTypes.string),
};

PostListItemHeader.defaultProps = {
  showHeaderIcons: true,
  showMoreButton: true,
  showProfileImage: true,
};

export default withFeedMetadataSettings(PostListItemHeader);
