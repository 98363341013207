import { ConnectedProps } from 'react-redux';
import { connect } from '../components/runtime-context';
import { getIsMobile } from '../store/basic-params/basic-params-selectors';

export type WithDeviceType = ConnectedProps<typeof connector>;

const connector = connect((state) => {
  const isMobile = getIsMobile(state);
  return {
    isMobile,
    isDesktop: !isMobile,
  };
});

export default function withDeviceType<
  T extends WithDeviceType = WithDeviceType,
>(WrappedComponent: React.ComponentType<T>) {
  return connector(WrappedComponent as React.ComponentType<WithDeviceType>);
}
